import * as React from "react";

import "./src/styles/global.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./src/styles/carousel.css";

import { ModalProvider } from "./src/context/ModalContext";

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
);
