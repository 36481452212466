import React, { useState, createContext } from "react";

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(null);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
